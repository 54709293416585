<template>

  <BaseSelect
    use-input
    ref="select"
    v-bind="{ label:'Cerca tra i clienti', 'hide-dropdown-icon': true, ...$attrs}"
    v-on="{ ...$listeners, ...listeners }"
    @input-value="inputValue"
    :value="value"
    :options="state.data"
    :display-value="fullname"
  >

    <template #prepend>
      <q-icon name="mdi-account-search" />
    </template>

    <template #no-option>
      <q-item>
        <q-item-section
          class="text-grey justify-between items-center"
          style="flex-direction:row!important">
          <span class="q-mr-sm">
            Nessun risultato per "<strong>{{ query }}</strong>"
          </span>
          <BaseBtn
            v-if="creable"
            @click="create"
            label="Crea"
            icon="mdi-account-plus"
          />
        </q-item-section>
      </q-item>
    </template>

    <template v-slot:option="scope">
      <q-item
        v-bind="scope.item.itemProps"
        v-on="scope.item.itemEvents"
      >
        <q-item-section>
          <q-item-label>{{ scope.item.opt.fullname }}</q-item-label>
          <q-item-label v-if="scope.item.opt.email" caption>
            <q-icon name="mdi-email"/> {{ scope.item.opt.email }}
          </q-item-label>
          <q-item-label v-if="scope.item.opt.phone" caption>
            <q-icon name="mdi-phone"/> {{ scope.item.opt.phone }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>

  </BaseSelect>

</template>

<script>
import Customers from '../../apis/customers.js'
import useApiCrud from '../../hooks/apiCrud.js'
import { ref, onMounted, watch } from '@vue/composition-api'

export default{
  name: 'CustomerAutocomplete',
  inheritAttrs: false,

  props: {
    creable: Boolean,

    inputPreserveValue: Boolean,

    value: {
      type: Object,
    },

  },

  setup(props, ctx ){
    const query = ref('')
    const fullname = ref('')

    const {
      apiIndex,
      apiShow,
      state,
    } = useApiCrud({
      ctx,
      api: Customers,
      routed: false,
    })

    const listeners = {
      filter( query, update, abort ){
        if( query.length < 2 ){
          abort()
          return
        }
        apiIndex({ query }).then( response => {
          update( response.data )
        })
      },
      input( value ){

        const emitValue = ctx.refs.select.$attrs[ 'emit-value' ] != undefined
        const id = emitValue ? value : value.id

        if( id == null ){
          fullname.value = ''
          ctx.emit( 'input', emitValue ? null : {} )
          return
        }

        if( emitValue ){
          ctx.emit( 'input', id )
          return
        }

        apiShow( id ).then( response => {
          ctx.emit( 'input', response.data )
        })
      },
    }

    onMounted( () => {
      watch(
        () => props.value.id,
        value => {
          if( value != null && ctx.refs.select.$attrs[ 'emit-value' ] != undefined ){
            apiShow( value ).then( response => fullname.value = response.data.fullname )
          }
        },
        { immediate: true }
      )
    })

    function inputValue( value )
    {
      query.value = value

      const emitValue = ctx.refs.select.$attrs[ 'emit-value' ] != undefined

      if( !props.inputPreserveValue && value.length > 0 ){
        fullname.value = ''
        ctx.emit( 'input', emitValue ? null : {} )
      }
    }

    function create()
    {
      apiShow( 'create' ).then( response => {
        //set name
        let name = query.value.split( ' ' )
        response.data.fullname = query.value
        response.data.lastname = name.shift()
        response.data.firstname = name.join( ' ' )
        //cler input
        ctx.refs.select.$refs.select.updateInputValue( '' )
        //select
        ctx.emit( 'input', response.data )
      })
    }

    return {
      create,
      inputValue,
      query,
      fullname,
      listeners,
      state,
    }
  }

}
</script>

