<template>
  <div>

    <div class="row q-col-gutter-sm">

      <div class="col-12 col-sm-10 col-md-4 col-lg-3 q-pr-sm-md">
        <BrandAutocomplete
          creable
          label="Marca"
          error-name="brand"
          :errors="errors"
          v-model="vehicle.model.brand" />
      </div>

      <div class="col-12 col-sm-2 col-lg-1 q-pr-md-md">
        <BaseInput
          label="Anno"
          error-name="year"
          :errors="errors"
          v-model.number="vehicle.model.year" />
      </div>

      <div class="col-12 col-md-6 col-lg-5 q-pr-lg-md">
        <VehiclesModelAutocomplete
          creable
          label="Modello"
          error-name="model"
          v-if="vehicle.model.brand.id&&vehicle.model.year"
          :errors="errors"
          :value="vehicle.model"
          @input="setModel"
        />

        <q-field
          v-else
          label="Modello"
          disable
          stack-label
          class="q-pb-md q-mb-sm"
          filled>
          <template v-slot:control>
            Seleziona prima la Marca e l'Anno
          </template>
        </q-field>
      </div>

      <div class="col-12 col-lg-3">
        <BaseInput
          label="Meccanica"
          error-name="mechanics"
          :errors="errors"
          v-model="vehicle.mechanics" />
      </div>

      <div class="col-12 col-sm-4 q-pr-sm-md">
        <BaseInput
          label="Targa"
          error-name="plate_number"
          :errors="errors"
          v-model="vehicle.plate_number" />
      </div>

      <div class="col-12 col-sm-4 q-pr-sm-md">
        <BaseDatePicker
          label="Immatricolazione"
          error-name="registration_date"
          :errors="errors"
          v-model="vehicle.registration_date" />
      </div>

      <div class="col-12 col-sm-4">
        <BaseInput
          label="Percorrenza (Km)"
          error-name="mileage"
          :errors="errors"
          v-model.number="vehicle.mileage" />
      </div>

      <div class="col-12 col-sm-6 q-pr-sm-md">
        <BaseInput
          label="Numero di serie"
          error-name="serial_number"
          :errors="errors"
          v-model="vehicle.serial_number" />
      </div>

      <div class="col-12 col-sm-6 ">
        <BaseInput
          label="Numero di telaio"
          error-name="frame_number"
          :errors="errors"
          v-model="vehicle.frame_number" />
      </div>

    </div>

    <!-- default slot -->
    <slot />

  </div>
</template>

<script>
import BrandAutocomplete from '../brands/BrandAutocomplete.vue'
import VehiclesModelAutocomplete from '../vehicles/VehicleModelAutocomplete.vue'
import useVModel from '../../hooks/vModel.js'
import { watch } from '@vue/composition-api'

export default{
  name: 'VehicleForm',

  components: {
    BrandAutocomplete,
    VehiclesModelAutocomplete,
  },

  props: {
    value: {
      type: Object,
    },
    errors : {
      type: Object,
    },
  },

  setup(props,ctx){
    const { vModel : vehicle } = useVModel( props.value, ctx )

    function resetModel(){
      vehicle.value.model.id = null
      vehicle.value.model.name = null
    }

    //on change brand-year, reset model
    watch( () => vehicle.value.model.brand, resetModel )
    watch( () => vehicle.value.model.year, resetModel )

    function setModel( value ){
      vehicle.value.model.id = value.id
      vehicle.value.model.name = value.name
    }

    return {
      setModel,
      vehicle,
    }
  }

}
</script>

